@import "../../settings";


.system-icon {
    $size: 120px;
    $size_medium: 160px;
    $size_large: 220px;
    $padding: 30px;

    height: $size;
    width: $size;
    min-height: $size;
    min-width: $size;
    display: flex;
    font-size: $size - ($padding * 2);
    justify-content: center;
    align-items: center;
    border-radius: $size_large;
    transition: height 1s ease, width 1s ease, font-size 1s ease;

    @media(min-width: map_get($breakpoints, medium)) {
        height: $size_medium;
        width: $size_medium;
        min-height: $size_medium;
        min-width: $size_medium;
        font-size: $size_medium - ($padding * 2);
    }

    @media(min-width: map_get($breakpoints, large)) {
        height: $size_large;
        width: $size_large;
        min-height: $size_large;
        min-width: $size_large;
        font-size: $size_large - ($padding * 2);
    }
}

.system-icon-label {
    text-align: center;
    font-size: 1.6rem;
    margin-top: 10px;

    @media(min-width: map_get($breakpoints, medium)) {
        font-size: 1.8rem;
    }

    @media(min-width: map_get($breakpoints, large)) {
        font-size: 2.5rem;
    }
}