@import "../../settings";


.dashbord {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    @media(min-width: map_get($breakpoints, medium)) {
        flex-direction: row;
    }
}


.subMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    color: #ffffff;
    //background-color: #0073A0;
    transition: height 0.5s ease, width 0.5s ease;

    // 横レイアウト 横1024 以下
    @media(max-width: map_get($breakpoints, large)) {
        //height: 100%;
        width: 200px;
    }

    // 横レイアウト 横1024 以上
    @media(min-width: map_get($breakpoints, large)) {
        //height: 100%;
        width: 300px;
        padding: 40px;
    }

    // 縦レイアウト 縦800px 以下
    @media(max-width: map_get($breakpoints, medium)) and (max-height: 800px) {
        //height: 200px;
        width: 100%;
    }

    // 縦レイアウト 縦800px 以上
    @media(max-width: map_get($breakpoints, medium)) and (min-height: 800px) {
        //height: 300px;
        width: 100%;
    }


    @at-root {
        #{&}__grid {
            //height: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;

            @media(min-width: map_get($breakpoints, medium)) {
                flex-direction: column;
            }
        }

        #{&}__cell1 {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            // 縦レイアウト
            @media(max-width: map_get($breakpoints, medium)) {
                width: 50%;
            }

            // 横レイアウト
            @media(min-width: map_get($breakpoints, medium)) {
                height: 300px;
            }
        }

        #{&}__cell2 {
            display: flex;
            justify-content: center;
            flex-direction: column;

            // 縦レイアウト
            @media(max-width: map_get($breakpoints, medium)) {
                width: 50%;
                align-items: center;
            }

            // 横レイアウト
            @media(min-width: map_get($breakpoints, medium)) {
                height: 50%;
                align-items: start;
            }
        }
    }
}


.information {
    width: 100%;
    overflow: hidden;
    flex-grow: 1;
    //background-color: #C8E6FF;

    display: flex;
    justify-content: center;
    align-items: center;

    // grid
    #{&}__grid {
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    #{&}__label {
        font-size: 4rem;
    }

    #{&}__value {
        font-size: 12.5rem;
    }

    #{&}__unit {
        font-size: 4rem;
    }

    #{&}__range {
        font-size: 2.5rem;
    }

}


.detail {
    width: 100%;
    overflow: hidden;
    flex-grow: 1;
    background-color: #C8E6FF;

    display: flex;
    justify-content: center;
    align-items: center;
}