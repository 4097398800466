@import "../settings";


// App全体のコンテナ
.app-container {
    display: flex;
    flex-direction: column;

    @media(min-width: map_get($breakpoints, medium)) {
        flex-direction: row;
    }
}

// メニューを配置するコンテナ
.menu-container {
}

// コンテンツを配置するコンテナ
.content-container {
    overflow: hidden;
}

// メニュー
.menu-bar {
    width: 100%;
    height: 80px;
    background-color: #50505A;

    @media(min-width: map_get($breakpoints, medium)) {
        width: 80px;
        height: 100%;
    }
}


// メニューリスト
.menu-list {
    height: 100%;
    width: 100%;
    padding: 0 40px;
    list-style: none;

    display: flex;
    flex-direction: row;
    align-items: center;

    @media(min-width: map_get($breakpoints, medium)) {
        padding: 40px 0;
        flex-direction: column;
    }

    li {
        margin: 0 1rem 0 0;

        @media(min-width: map_get($breakpoints, medium)) {
            margin: 0 0 1rem 0;
        }
    }
}
